<template>
  <div>
    <v-dialog v-model="shown" persistent>
      <template #default>
        <v-card
          class="mx-auto px-6 py-8"
          :min-width="$vuetify.display.mobile ? '320px' : '420px'"
        >
          <v-card-title class="headline d-flex justify-center mb-2"
            ><i><strong>Beta Phase</strong></i></v-card-title
          >
          <v-form v-model="form" @submit.prevent="onSubmit">
            <v-text-field
              v-model="email"
              data-cy="login"
              :readonly="loading"
              :rules="[required]"
              class="mb-2"
              clearable
              label="Email"
              type="email"
            ></v-text-field>

            <v-text-field
              v-model="password"
              data-cy="password"
              :readonly="loading"
              :rules="[required]"
              clearable
              label="Password"
              placeholder="Enter your password"
              type="password"
            ></v-text-field>

            <v-btn
              :disabled="!form"
              :loading="loading"
              block
              color="primary"
              transition="fade"
              size="large"
              type="submit"
              variant="elevated"
            >
              Se connecter
            </v-btn>
          </v-form>
          <v-divider class="my-4"><strong>Ou</strong></v-divider>
          <v-btn
            :loading="loading"
            block
            data-cy="signupBtn"
            color="primary"
            transition="fade"
            size="large"
            variant="elevated"
            @click="onClickSignUp"
            >S'inscrire</v-btn
          >
        </v-card>
        <!--   <div class="d-flex justify-space-between">
      <v-btn
        color="primary"
        variant="text"
        :to="{ name: 'forgotPassword' }"
        size="small"
      >
        Forgot password?
      </v-btn>
      <v-btn color="primary" variant="text" size="small">
        Pas de compte?
      </v-btn>
    </div> -->
        <!--      Essai
    <v-blaze template="atForm" tag="b-container" />

    Totot  -->
      </template>
    </v-dialog>
  </div>
</template>

<script setup>
import { captchalogin } from '../../../api/methods/Connect';
import { VForm } from 'vuetify/lib/components/VForm/index.mjs';
import { Meteor } from 'meteor/meteor';
import log from 'loglevel';
//import VBlaze from '../../components/Generic/vBlaze.ts';
import { useRouter, useRoute } from 'vue-router';
import { onMounted, onUnmounted, ref } from 'vue';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { useNotifier } from 'vuetify-notifier';

const router = useRouter();
const route = useRoute();
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
const $notifier = useNotifier();

const shown = ref(true);

onMounted(() => {
  // console.log('SignIn');
  // console.log('SignIn Bis');
});

onUnmounted(() => {});

let form = ref(false);
let email = ref(null);
let password = ref(null);
let loading = ref(false);
let messageError = ref('');

function required(v) {
  return !!v || 'Field is required';
}

/* function loginWithGoogle() {
  // console.log('GOOGLE', Accounts.loginServiceConfiguration.find());
  Meteor.loginWithGoogle(
    {
      loginStyle: 'popup',
      redirectUrl: 'http://localhost:3000/map/-1',
      forceApprovalPrompt: false,
      loginUrlParameters: { prompt: 'select_account' },
      requestPermissions: ['email', 'profile']
    },
    (error, result) => {
      if (error) console.log('Error Google Login: ', error);
      else {
        console.log('Result Google Login', result);
        const path = `/map/-1`;
        if (route.path !== path) router.push(path);
        // this.$router.push(path);
      }
    }
  );
} */

async function onSubmit() {
  if (!form.value) return;

  loading.value = true;
  await recaptchaLoaded();

  //Execute reCAPTCHA with action "login".
  const token = await executeRecaptcha('login');
  captchalogin
    .callAsync(
      /*  Meteor.call(
        'captcha.login', */
      {
        token
      }
    )
    .then(() => {
      Meteor.loginWithPassword(email.value, password.value, (error1) => {
        if (error1) {
          console.log('Error Login', error1);
          $notifier.alertError(
            "L'email et le mot de passe ne correspondent pas. Essayez de nouveau."
          );
          loading.value = false;
        } else {
          log.info('Login : ');
          log.info(Meteor.userId);
          const path = `/app/calendar`;
          if (route.path !== path) router.push(path);
          router.push(path);
        }
      });

      log.info('LOGIN');
    })
    .catch((error) => {
      log.info('Error Captcha', error);
      messageError.value = 'Captcha error';
      loading.value = false;
    });
}

function onClickSignUp() {
  router.push(`/signup`);
}
</script>

<style lang="scss" scoped>
.v-overlay {
  background-image: url('/bg.png');
  background-size: cover;
}
div.check {
  color: darkred;
  font-size: 80%;
}

div.checkwhite {
  color: white;
  font-size: 80%;
}
.login-wrapper {
  margin-top: 45px;
}

#inner {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
